var processInclude = require("suzano/util");

$(document).ready(function () {
    if(!$('#vueAppCheckout').length){
        processInclude(require('../components/modalCep'));
    }
    processInclude(require('../components/modalTrackOrder'));
    processInclude(require('../sellersCarousel'));
    processInclude(require('../unavailableModal'));
});
